import React, { Component } from 'react';

import CookieCategory from './CookieCategory';
import CookieSummary from './CookieSummary';

import { emitEvent } from '../utils/cookies';

export default class CookiesDetails extends Component {
    constructor(props) {
        super(props);

        // Set the component's initial state.
        this.state = {
            updateToggle: false,
        };
    }

    // ------------------------------------
    // Event Functions
    // ------------------------------------
    refreshSummary = () => {
        const { data } = this.props;

        this.setState({
            updateToggle: !this.state.updateToggle,
        });

        emitEvent('eprivacy_toggle', data);
    };

    // ------------------------------------
    // Public Functions
    // ------------------------------------
    render() {
        const { data } = this.props;

        if (!data) return null;

        return (
            <div className="container">
                <div
                    className="bmw-eprivacy-intro"
                    dangerouslySetInnerHTML={{ __html: data.body }}
                />

                <CookieSummary
                    cookies={data.cookie_list}
                    refresh={this.state.updateToggle}
                />

                {data.cookie_list.map((item, idx) => {
                    return (
                        <CookieCategory
                            category={item}
                            onChange={this.refreshSummary}
                            key={`cookie-cat-${idx}`}
                        />
                    );
                })}

                <div
                    className="bmw-eprivacy-intro"
                    dangerouslySetInnerHTML={{ __html: data.bottom_section }}
                />
            </div>
        );
    }
}
