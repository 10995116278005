import React, { Component } from 'react';

import Accordion from './Accordion';

export default class CookieItem extends Component {
    _renderHeader() {
        const { name } = this.props.cookie;

        return <p>{name}</p>;
    }

    _renderBody() {
        const { cookie, owner } = this.props;
        const { description, expiry_length } = cookie;

        return (
            <React.Fragment>
                <div className="bmw-eprivacy-cookies__row">
                    <span>Purpose:</span>
                    <span>{description}</span>

                    <span>Who's placing it:</span>
                    <span>{owner}</span>

                    <span>Expires after:</span>
                    <span>{expiry_length}</span>
                </div>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div className="bmw-eprivacy-section">
                <Accordion
                    header={this._renderHeader()}
                    body={this._renderBody()}
                />
            </div>
        );
    }
}
