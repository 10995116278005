import classNames from 'classnames';
import React from 'react';
import Cookies from 'universal-cookie';

//
// Simplistic function component to represent a single item for the cookie summary block.
//
export default function CookieSummaryItem(props) {
    const item = props.item;
    const checkCookie = new Cookies();

    const hasCookieSet =
        checkCookie.get(item.cookie_reference) == 'true' ||
        item.necessary == true
            ? true
            : false;

    let statusText = hasCookieSet ? 'On' : 'Off';

    if (item.necessary) statusText = 'Always On';

    const symbolClasses = classNames('bmw-eprivacy-summary__symbol', {
        '-given': hasCookieSet,
        '-revoked': !hasCookieSet,
    });

    return (
        <div className="bmw-eprivacy-summary__row">
            <a
                className="bmw-eprivacy-summary__name"
                href={`#bmw-eprivacy-${item.cookie_reference}`}
                target="_self"
            >
                <span className="bmw-eprivacy-summary__name-text">
                    {item.name}
                </span>
            </a>

            <div></div>

            <div className="bmw-eprivacy-summary__status">
                <span className="bmw-eprivacy-summary__status-text">
                    {statusText}
                </span>

                <span className={symbolClasses} />
            </div>
        </div>
    );
}
