import axios from 'axios';

export const getCookieData = (url, client, successCallback, errorCallback) => {
    console.log(`Finding data for client ${client}`);

    const response = false;
    const request = axios.get(url).then(successCallback).catch(errorCallback);

    return response;
};
