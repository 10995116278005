import React, { Component } from 'react';

import Accordion from './Accordion';
import CookieItem from './CookieItem';

export default class CookieGroup extends Component {
    renderGroupInfo() {
        const { purpose, owner } = this.props.group;
        return (
            <React.Fragment>
                <div className="bmw-eprivacy-cookies__row">
                    <p>Purpose:</p>
                    <span>
                        <p dangerouslySetInnerHTML={{ __html: purpose }} />
                    </span>
                    {/* </div>
        <div className="bmw-eprivacy-cookies__row"> */}
                    <p>Who's placing it:</p>
                    <p>{owner}</p>
                </div>
            </React.Fragment>
        );
    }

    _renderHeader() {
        const { name } = this.props.group;

        return <p>{name}</p>;
    }

    _renderBody() {
        const { cookies, owner } = this.props.group;

        return (
            <React.Fragment>
                {this.renderGroupInfo()}

                {cookies.map((cookie, idx) => {
                    return (
                        <CookieItem
                            cookie={cookie}
                            key={`cookie-item-${idx}`}
                            owner={owner}
                        />
                    );
                })}
            </React.Fragment>
        );
    }

    render() {
        return (
            <div className="bmw-eprivacy-section">
                <Accordion
                    header={this._renderHeader()}
                    body={this._renderBody()}
                />
            </div>
        );
    }
}
