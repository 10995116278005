import '@babel/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

import '../scss/index.scss';
import CookieController from './CookieController';
import CookieBanner from './CookieBanner';

// Cookie controller page.
const CookieControllerContainer = document.getElementById('bmw-eprivacy-app');

if (CookieControllerContainer) {
    const client = CookieControllerContainer.getAttribute('data-client');
    const api = CookieControllerContainer.getAttribute('data-api');

    ReactDOM.render(
        <CookieController client={client} api={api} />,
        CookieControllerContainer
    );
}

// Cookie banner, only shown if not on the cookie controller page.
const CookieBannerContainer = document.getElementById('bmw-eprivacy-banner');

// TODO - Add check to only show if not on cookie controller page.
if (CookieBannerContainer) {
    const client = CookieBannerContainer.getAttribute('data-client');
    const api = CookieBannerContainer.getAttribute('data-api');

    ReactDOM.render(
        <CookieBanner client={client} api={api} />,
        CookieBannerContainer
    );
}
