import React, { Component } from 'react';

import CookieSummaryItem from './CookieSummaryItem';

export default class CookieSummary extends Component {
    // ------------------------------------
    // Public Functions
    // ------------------------------------
    render() {
        return (
            <div className="bmw-eprivacy-summary">
                {this._renderHeader()}
                {this._renderItemList()}
            </div>
        );
    }

    // ------------------------------------
    // Private Functions
    // ------------------------------------
    _renderHeader() {
        return (
            <React.Fragment>
                <h2>You have the control.</h2>

                <p>
                    You have activated/deactivated the following cookie
                    categories.
                </p>

                <span className="bmw-eprivacy-summary__thanks">
                    <svg
                        id="svg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="30"
                        viewBox="40, 40, 320, 320"
                    >
                        <g id="svg">
                            <path
                                id="path0"
                                d="M120.417 20.024 C 78.250 30.963,42.906 40.127,41.875 40.389 L 40.000 40.865 40.000 157.365 C 40.000 262.103,40.136 274.321,41.344 278.390 C 49.268 305.082,83.271 336.240,141.667 370.318 C 158.593 380.196,197.514 400.000,200.000 400.000 C 202.470 400.000,241.307 380.250,258.333 370.336 C 316.387 336.532,350.744 305.044,358.656 278.390 C 360.813 271.124,360.703 41.156,358.542 40.509 C 347.547 37.219,201.244 0.018,199.493 0.067 C 198.168 0.104,162.583 9.085,120.417 20.024 M261.042 57.603 L 320.000 72.795 319.992 169.523 L 319.984 266.250 318.032 270.417 C 309.744 288.108,268.723 318.925,216.013 347.056 C 198.099 356.617,201.923 356.627,183.811 346.972 C 129.492 318.019,86.636 285.289,80.954 268.417 C 80.259 266.355,80.000 239.373,80.000 169.188 L 80.000 72.791 139.792 57.299 C 172.677 48.778,200.146 41.942,200.833 42.109 C 201.521 42.275,228.615 49.248,261.042 57.603 M186.812 81.216 C 172.091 84.778,158.807 95.550,152.228 109.260 C 147.212 119.712,146.667 123.017,146.667 142.958 L 146.667 160.000 140.000 160.000 L 133.333 160.000 133.333 213.333 L 133.333 266.667 200.000 266.667 L 266.667 266.667 266.667 213.333 L 266.667 160.000 260.000 160.000 L 253.333 160.000 253.333 142.958 C 253.333 123.017,252.788 119.712,247.772 109.260 C 237.217 87.265,211.225 75.308,186.812 81.216 M211.493 109.891 C 223.237 115.950,226.648 124.455,226.660 147.708 L 226.667 160.000 199.900 160.000 L 173.133 160.000 173.561 144.375 C 174.125 123.778,176.121 118.283,185.347 111.924 C 192.545 106.964,204.081 106.067,211.493 109.891 M207.917 182.399 C 220.447 188.147,223.084 204.545,213.018 214.122 L 210.000 216.994 210.000 231.830 L 210.000 246.667 200.000 246.667 L 190.000 246.667 190.000 231.830 L 190.000 216.994 186.982 214.122 C 171.354 199.253,188.389 173.441,207.917 182.399 "
                                stroke="none"
                                fill="#000000"
                                fillRule="evenodd"
                            />
                        </g>
                    </svg>

                    <span>We thank you for your consent.</span>
                </span>
            </React.Fragment>
        );
    }

    _renderItemList() {
        const { cookies } = this.props;

        return cookies.map((item, idx) => {
            return (
                <CookieSummaryItem
                    key={`bmw-eprivacy-row-${idx}`}
                    item={item}
                />
            );
        });
    }
}
