import React, { Component } from 'react';
import classnames from 'classnames';

export default class Accordion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    onToggleCookies(ev) {
        const { expanded } = this.state;
        this.setState({ expanded: !expanded });
    }

    render() {
        const listClasses = classnames('bmw-eprivacy-accordion', {
            '-expanded': this.state.expanded,
        });

        return (
            <div className={listClasses}>
                <div
                    className="bmw-eprivacy-accordion__header"
                    onClick={this.onToggleCookies.bind(this)}
                >
                    {this.props.header}
                </div>
                <div className="bmw-eprivacy-accordion__body">
                    {this.props.body}
                </div>
            </div>
        );
    }
}
