import Cookies from 'universal-cookie';

class EPrivacy {
    constructor() {
        this.getConsent = this.getConsent.bind(this);
    }

    getConsent(cookieReference) {
        const cookies = new Cookies();
        const hasCookieSet = cookies.get(cookieReference);

        return hasCookieSet == 'true';
    }
}

export const emitEvent = (event_name, cookies) => {
    const { cookie_list } = cookies;

    const event_data = cookie_data(cookie_list);

    const custom_event = new CustomEvent(event_name, { detail: event_data });
    document.dispatchEvent(custom_event);
};

const cookie_data = (cookie_list) => {
    const cookie_data = {
        consentBanner: cookie_banner(),
    };

    const cookie = new Cookies();

    cookie_list.forEach((item) => {
        const hasCookieSet = cookie.get(item.cookie_reference);

        if (item.necessary === true) {
            cookie_data[item.cookie_reference] = true;
        } else {
            cookie_data[item.cookie_reference] = hasCookieSet === 'true';
        }
    });

    return cookie_data;
};

const cookie_banner = () => {
    const cookies = new Cookies();
    const hasCookieSet = cookies.get(process.env.BANNER_COOKIE_KEY);

    return hasCookieSet == 'true';
};
